import { FC, TextareaHTMLAttributes } from "react";

import "../styles/components/customInput.css";

export interface ICustomTextarea
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  required?: boolean;
  wrapperClassname?: string;
}

export const CustomTextarea: FC<ICustomTextarea> = ({
  className,
  label,
  id,
  wrapperClassname,
  required,
  ...rest
}) => {
  return (
    <div className={`custom-input-wrapper ${wrapperClassname || ""}`}>
      {label && (
        <label htmlFor={id} className="custom-input-label">
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <textarea
        className={`custom-textarea ${className || ""}`}
        {...rest}
      ></textarea>
    </div>
  );
};
